<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      default-active="/index"
      unique-opened
      router
      mode="horizontal"
    >
      <el-menu-item
        :index="item.url"
        v-for="(item, index) in menus"
        :key="index"
      >
        <span
          ><img
            :src="activeId == item.url ? item.active : item.img"
            class="img-icon"
        /></span>
        <span slot="title">{{ item.text }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
export default {
  props: {
    activeId: {
      type: String,
      default: "/index",
    },
  },
  data() {
    return {
      menus: [],
      menusa: [
        {
          text: "首页",
          url: "/index",
          img: require("@/assets/menu/index.png"),
          active: require("@/assets/menu/index_active.png"),
          show: "all",
        },
        {
          text: "任务",
          url: "/taskcenter",
          img: require("@/assets/menu/opportunity.png"),
          active: require("@/assets/menu/opportunity_active.png"),
        },
        {
          text: "商城",
          url: "/shoppingMall",
          img: require("@/assets/menu/mall.png"),
          active: require("@/assets/menu/mall_active.png"),
        },
        {
          text: "我的",
          url: "/mine",
          img: require("@/assets/menu/my.png"),
          active: require("@/assets/menu/my_active.png"),
        },
      ],
      menusb: [
        {
          text: "首页",
          url: "/index",
          img: require("@/assets/menu/index.png"),
          active: require("@/assets/menu/index_active.png"),
          show: "all",
        },
        {
          text: "商机",
          url: "/customerManage",
          img: require("@/assets/menu/opportunity.png"),
          active: require("@/assets/menu/opportunity_active.png"),
        },
        {
          text: "工作台",
          url: "/staging",
          img: require("@/assets/menu/workbench.png"),
          active: require("@/assets/menu/workbench_active.png"),
        },
        {
          text: "商城",
          url: "/shoppingMall",
          img: require("@/assets/menu/mall.png"),
          active: require("@/assets/menu/mall_active.png"),
        },
        {
          text: "我的",
          url: "/mine",
          img: require("@/assets/menu/my.png"),
          active: require("@/assets/menu/my_active.png"),
        },
      ],
      userType: "", //用户类型(0-系统用记，1-企业用户，2-个人，3-普通用户，4-讲师,5-业务员)
    };
  },
  created() {
    this.userType = getToken("userType");
    if (this.userType == "3" || this.userType == "6") {
      this.menus = this.menusa;
      //this.menus.splice(1, 0, taskItem);
    } else if (this.userType == "1" || this.userType == "5") {
      this.menus = this.menusb;
      //this.menus.splice(1, 0, customerItem);
    }
  },
};
</script>

<style lang="scss" scoped>
.img-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
</style>