import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import home from '@/views/home.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login.vue'),
	meta: { title: '登录' }
  },
  {
    path: '/',
    name: 'Home',
    component: home,
	redirect: '/index',
	children: [
		{
			path: 'index',
			component: () => import('@/views/index.vue'),
			name: 'Index',
			meta: { title: '首页' }
		},
		{
			path: 'customerManage',
			component: () => import('@/views/customer/customer_manage.vue'),
			name: 'CustomerManage',
			meta: { title: '商机' }
		},		
		{
			path: 'staging',
			component: () => import('@/views/staging/staging.vue'),
			name: 'Staging',
			meta: { title: '工作台' }
		},
		{
			path: 'carRework',
			component: () => import('@/views/customer/car_rework.vue'),
			name: 'carRework',
			meta: { title: '车辆返工' }
		},
		{
			path: 'gangwei',
			component: () => import('@/views/staging/gangwei.vue'),
			name: 'gangwei',
			meta: { title: '岗位职责' }
		},
		{
			path: 'rules',
			component: () => import('@/views/staging/rules/rules.vue'),
			name: 'rules',
			meta: { title: '规章制度' }
		},
		{
			path: 'sixqs',
			component: () => import('@/views/staging/sixqs.vue'),
			name: 'sixqs',
			meta: { title: '6s管理' }
		},
		{
			path: 'yeji',
			component: () => import('@/views/staging/yeji.vue'),
			name: 'yeji',
			meta: { title: '业绩积分' }
		},
		{
			path: 'baoming',
			component: () => import('@/views/staging/baoming.vue'),
			name: 'baoming',
			meta: { title: '报名信息' }
		},
		{
			path: 'shebeibaoming',
			component: () => import('@/views/staging/shebeibaoming.vue'),
			name: 'shebeibaoming',
			meta: { title: '设备报名' }
		},
		{
			path: 'shoppingMall',
			component: () => import('@/views/shoppingMall/shoppingMall.vue'),
			name: 'ShoppingMall',
			meta: { title: '商城' }
		},
		{
			path: 'forum',
			component: () => import('@/views/shoppingMall/forum/list.vue'),
			name: 'forum',
			meta: { title: '论坛管理' }
		},
		{
			path: 'service',
			component: () => import('@/views/shoppingMall/service/list.vue'),
			name: 'service',
			meta: { title: '维修资料' }
		},
		{
			path: 'forumDetail/:id',
			component: () => import('@/views/shoppingMall/forum/forumDetail.vue'),
			name: 'forumDetail',
			meta: { title: '帖子详情' }
		},
		{
			path: 'onlineCourses',
			component: () => import('@/views/shoppingMall/onlineCourses/onlineCourses.vue'),
			name: 'OnlineCourses',
			meta: { title: '线上课程' }
		},
		{
			path: 'offlineCourses',
			component: () => import('@/views/shoppingMall/offlineCourses/offlineCourses.vue'),
			name: 'offlineCourses',
			meta: { title: '线下集训' }
		},
		{
			path: 'courseDetails/:id',
			component: () => import('@/views/shoppingMall/onlineCourses/courseDetails.vue'),
			name: 'CourseDetails',
			meta: { title: '线上课程详情' }
		},
		{
			path: 'offlinCourseDetails/:id',
			component: () => import('@/views/shoppingMall/offlineCourses/offlineCourseDetails.vue'),
			name: 'offlinCourseDetails',
			meta: { title: '线下集训课程详情' }
		},
		{
			path: 'diagnosticEquipment/:type',
			component: () => import('@/views/shoppingMall/diagnosticEquipment/diagnosticEquipment.vue'),
			name: 'DiagnosticEquipment',
			meta: { title: '诊断设备/实物书籍' }
		},
		{
			path: 'equipCommodityDetail',
			component: () => import('@/views/shoppingMall/commodity/equipCommodityDetail.vue'),
			name: 'EquipCommodityDetail',
			meta: { title: '诊断设备详情' }
		},
		{
			path: 'liveCourses',
			component: () => import('@/views/shoppingMall/liveCourses/liveCourses.vue'),
			name: 'LiveCourses',
			meta: { title: '直播课程' }
		},
		{
			path: 'liveCoursesDetail/:id',
			component: () => import('@/views/shoppingMall/liveCourses/liveCoursesDetail.vue'),
			name: 'liveCoursesDetail',
			meta: { title: '直播课程详情' }
		},
		{
			path: 'mine',
			component: () => import('@/views/mine/mine.vue'),
			name: 'Mine',
			meta: { title: '我的' }
		},
		{
			path: 'message',
			component: () => import('@/views/message/index.vue'),
			name: 'message',
			meta: { title: '消息' }
		},
		{
			path: 'order',
			component: () => import('@/views/mine/order/index.vue'),
			name: 'Order',
			meta: { title: '全部订单' }
		},
		{
			path: 'taskcenter',
			component: () => import('@/views/task/task_center.vue'),
			name: 'TaskCenter',
			meta: { title: '任务' }
		},
		{
			path: 'exam_body',
			component: () => import('@/views/task/exam_body.vue'),
			name: 'exam_body',
			meta: { title: '线上考试' }
		},
		{
			path: 'subtaskResult',
			component: () => import('@/views/task/subtaskResult.vue'),
			name: 'subtaskResult',
			meta: { title: '考试结果' }
		},
		{
			path: 'courseAssign',
			component: () => import('@/views/staging/courseAssign/index.vue'),
			name: 'courseAssign',
			meta: { title: '课程指派' }
		},
		{
			path: 'taskAssignment',
			component: () => import('@/views/staging/taskAssignment/index.vue'),
			name: 'taskAssignment',
			meta: { title: '任务指派' }
		},
		{
			path: 'salesman',
			component: () => import('@/views/staging/salesman.vue'),
			name: 'salesman',
			meta: { title: '我的推广员' }
		},
		{
			path: 'satisfaction',
			component: () => import('@/views/staging/satisfaction/index.vue'),
			name: 'satisfaction',
			meta: { title: '满意忠诚度' }
		},
		{
			path: 'money',
			component: () => import('@/views/mine/money.vue'),
			name: 'money',
			meta: { title: '我的余额' }
		},
		{
			path: 'honor',
			component: () => import('@/views/mine/honor.vue'),
			name: 'honor',
			meta: { title: '我的荣誉' }
		},
		{
			path: 'result',
			component: () => import('@/views/mine/result.vue'),
			name: 'result',
			meta: { title: '我的绩效' }
		},
		{
			path: 'collect',
			component: () => import('@/views/mine/collect.vue'),
			name: 'collect',
			meta: { title: '我的收藏' }
		},
		{
			path: 'addressManger',
			component: () => import('@/views/mine/addressManger/index.vue'),
			name: 'addressManger',
			meta: { title: '地址管理' }
		},
		{
			path: 'suggestManger',
			component: () => import('@/views/mine/suggest/index.vue'),
			name: 'suggestManger',
			meta: { title: '意见反馈' }
		},
		{
			path: 'phone',
			component: () => import('@/views/mine/phone.vue'),
			name: 'phone',
			meta: { title: '修改手机' }
		},
		{
			path: 'passwordEdit',
			component: () => import('@/views/mine/passwordEdit.vue'),
			name: 'phone',
			meta: { title: '修改密码' }
		},
		{
			path: 'aboutus',
			component: () => import('@/views/mine/aboutus.vue'),
			name: 'aboutus',
			meta: { title: '关于我们' }
		},		
		{
			path: 'subtaskList',
			component: () => import('@/views/task/task_detail/subtask_list.vue'),
			name: 'subtasklist',
			meta: { title: '子任务列表' }
		},
		{
			path: 'taskdetail',
			component: () => import('@/views/task/task_detail/task_detail'),
			name: 'taskdetail',
			meta: { title: '任务详情' }
		},
		{
			path: 'coursedetail',
			component: () => import('@/views/task/task_detail/course_detail'),
			name: 'coursedetail',
			meta: { title: '课程详情' }
		},
		{
			path: 'coursederieslist',
			component: () => import('@/views/shoppingMall/onlineCourses/courseSeriesList'),
			name: 'coursederieslist',
			meta: { title: '全部课程' }
		},
		{
			path: 'skillMatching',
			component: () => import('@/views/staging/skillMatching/index.vue'),
			name: 'skillMatching',
			meta: { title: '技能匹配' }
		},
		{
			path: 'kpiAssignment',
			component: () => import('@/views/staging/kpiAssignment/index.vue'),
			name: 'kpiAssignment',
			meta: { title: 'KPI指派' }
		},
		{
			path: 'integralStatistics',
			component: () => import('@/views/staging/integralStatistics/integralStatistics.vue'),
			name: 'IntegralStatistics',
			meta: { title: '积分统计' }
		},
		{
			path: 'myPerformance',
			component: () => import('@/views/staging/myPerformance/myPerformance.vue'),
			name: 'MyPerformance',
			meta: { title: '我的业绩' }
		},
		{
			path: 'consumption',
			component: () => import('@/views/staging/consumption.vue'),
			name: 'consumption',
			meta: { title: '消费明细' }
		},
		{
			path: 'recharge',
			component: () => import('@/views/staging/recharge.vue'),
			name: 'recharge',
			meta: { title: '充值明细' }
		},
		{
			path: 'placementExam',
			component: () => import('@/views/staging/placementExam/list.vue'),
			name: 'placementExam',
			meta: { title: '独立考试' }
		},
		{
			path: 'editPlacementExam',
			component: () => import('@/views/staging/placementExam/editPlacementExam.vue'),
			name: 'editPlacementExam',
			meta: { title: '申请独立考试' }
		},
		{
			path: 'staffManagement',
			component: () => import('@/views/staging/staffManagement/index.vue'),
			name: 'staffManagement',
			meta: { title: '员工管理' }
		},
		{
			path: 'organizationalStructure',
			component: () => import('@/views/staging/organizationalStructure/index.vue'),
			name: 'organizationalStructure',
			meta: { title: '组织架构' }
		},
		{
			path: 'publicCustomer',
			component: () => import('@/views/customer/public_customer.vue'),
			name: 'publicCustomer',
			meta: { title: '客户管理' }
		},
		{
			path: 'onlineCustomer',
			component: () => import('@/views/staging/onlineCustomer.vue'),
			name: 'onlineCustomer',
			meta: { title: '线上客户' }
		},
		{
			path: 'commodityDetail',
			component: () => import('@/views/shoppingMall/commodity/commodityDetail.vue'),
			name: 'commodityDetail',
			meta: { title: '商品详情' }
		},
		{
			path: 'virtualCommodityDetail',
			component: () => import('@/views/shoppingMall/commodity/virtualCommodityDetail.vue'),
			name: 'virtualCommodityDetail',
			meta: { title: '商品详情' }
		},
		{
			path: 'confirmOrder',
			component: () => import('@/views/shoppingMall/commodity/commodityOrder.vue'),
			name: 'confirmOrder',
			meta: { title: '订单确认' }
		},
		{
			path: 'zhifu',
			component: () => import('@/views/shoppingMall/commodity/zhifubao.vue'),
			name: 'zhifu',
			meta: { title: '支付' }
		},
		{
			path: 'kpiDetail/:id',
			component: () => import('@/views/staging/kpiAssignment/kpiDetail.vue'),
			name: 'kpiDetail',
			meta: { title: 'kpi详情' }
		},
		{
			path: 'offlineCoursesList',
			component: () => import('@/views/task/offlineCourses.vue'),
			name: 'offlineCoursesList',
			meta: { title: '线下课程' }
		},
		{
			path: 'competency',
			component: () => import('@/views/staging/competency.vue'),
			name: 'competency',
			meta: { title: '胜任力' }
		},
		{
			path: 'recordLearn',
			component: () => import('@/views/score/recordLearn.vue'),
			name: 'recordLearn',
			meta: { title: '学习能力评分' }
		},
		{
			path: 'workScore',
			component: () => import('@/views/score/workScore.vue'),
			name: 'workScore',
			meta: { title: '工作能力评分' }
		},
		{
			path: 'carScore',
			component: () => import('@/views/score/carScore.vue'),
			name: 'carScore',
			meta: { title: '车辆返工评分' }
		},
		{
			path: 'csiScore',
			component: () => import('@/views/score/csiScore.vue'),
			name: 'csiScore',
			meta: { title: '客户满意度评分' }
		},
		{
			path: 'clyScore',
			component: () => import('@/views/score/clyScore.vue'),
			name: 'clyScore',
			meta: { title: '忠诚度评分' }
		},
		{
			path: '6sScore',
			component: () => import('@/views/score/6sScore.vue'),
			name: '6sScore',
			meta: { title: '6s管理明细' }
		},
		{
			path: 'ruleScore',
			component: () => import('@/views/score/ruleScore.vue'),
			name: 'ruleScore',
			meta: { title: '规则制度评分' }
		},
		{
			path: 'majorScore',
			component: () => import('@/views/score/majorScore.vue'),
			name: 'majorScore',
			meta: { title: '专业功力评分' }
		},
		{
			path: 'fightScore',
			component: () => import('@/views/score/fightScore.vue'),
			name: 'fightScore',
			meta: { title: '战斗能力评分' }
		},
		{
			path: 'profitScore',
			component: () => import('@/views/score/profitScore.vue'),
			name: 'profitScore',
			meta: { title: '创利能力评分' }
		},
		{
			path: 'assessScore',
			component: () => import('@/views/score/assessScore.vue'),
			name: 'assessScore',
			meta: { title: '创新能力评分' }
		},
		{
			path: 'recentLearn',
			component: () => import('@/views/index/recentLearn.vue'),
			name: 'recentLearn',
			meta: { title: '近期课程' }
		},
		{
			path: 'customerDetail',
			component: () => import('@/views/customer/customer_detail.vue'),
			name: 'customerDetail',
			meta: { title: '客户详情' }
		}
		
	]
  }
]

const router = new VueRouter({
	routes
})
console.log(router);

router.beforeEach((to, from, next) => {
	console.log(to);
	if (to.meta.title) {
		document.title = to.meta.title
	}
	let token = Cookies.get('token');
	if (token && to.path !== '/login') {
		// 有token 但不是去 login页面 通过
		next()
	} else if (token && to.path === '/login') {
		// 有token 但是去 login页面 不通过 重定向到首页
		next('/')
	} else if (!token && to.path !== '/login') {
		// 没有token 但不是去 login页面 不通过（未登录不给进入）
		next('/login')
	} else {
		// 剩下最后一种 没有token 但是去 login页面 通过
		next()
	}
})
export default router
