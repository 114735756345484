<template>
  <div class="app-wrapper">
    <div class="middle-wrapper">
      <div class="topmenu-container">
        <div class="topmenu-container-left">
          <div class="logo-box">{{userInfo && userInfo.companyName || ''}}</div>
          <sidebar class="sidebar-container" :activeId="activeId"></sidebar>
        </div>
        <div class="topmenu-container-right">
          <el-menu unique-opened router mode="horizontal" default-active="1">
            <el-menu-item
              :index="item.url"
              v-for="(item, index) in menusright"
              :key="index"
              @click="menuClick(item)"
            >
              <span
                ><img
                  :src="activeId == item.url ? item.active : item.img"
                  class="img-icon"
              /></span>
              <span slot="title">{{ item.text }}</span>
            </el-menu-item>
          </el-menu>
        </div>
      </div>
      <div class="main-container">
        <transition name="fade-transform" mode="out-in">
          <router-view v-if="!$route.meta.link" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
export default {
  components: {
    sidebar,
  },
  data() {
    return {
      activeId: "/index",
	  userInfo: {},
      menusright: [
        {
          text: "消息",
          url: "/message",
          img: require("@/assets/menu/message.png"),
          active: require("@/assets/menu/message_active.png"),
        },
        {
          text: "退出",
          url: "",
          img: require("@/assets/menu/exit.png"),
          active: require("@/assets/menu/exit_active.png"),
        },
      ],
    };
  },
  watch: {
    $route: {
      handler(val, oldval) {
        this.activeId = val.path;
        // 当前活动路由
        console.log(this.activeId);

      },
    },
  },
  created() {
	  this.$store.dispatch("GetUserInfo", null).then((res) => {
		  this.userInfo = res.data;
      if(this.userInfo.userType==5){
        this.userInfo.companyName="宝达之星"
      }
	  });
  },
  methods: {
    menuClick(item) {
      if (item.text == "退出") {
        this.$confirm("确定退出登录吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$store.dispatch("LogOut", null).then((res) => {
              this.$router.push({ path: "/login" }).catch(() => {});
            });
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.app-wrapper {
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: #fafbff;
  .middle-wrapper {
    min-width: 1200px;
    max-width: 80vw;
    margin: 0 auto;
    .topmenu-container {
      min-width: 1200px;
      max-width: 80vw;
      margin: 0 auto;
      height: 60px !important;
      position: fixed;
      font-size: 0;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1001;
      overflow: hidden;
      -webkit-box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
      box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
      display: flex;
      background-color: #fafbff;
      .el-menu {
        background-color: #fafbff;
      }
      .topmenu-container-left {
        min-width: 980px;
        max-width: 60vw;
        display: flex;
        .logo-box {
          width: 300px;
          height: 60px;
          line-height: 60px;
          font-size: 20px;
          margin-right: 10px;
          color: #ffffff;
          background-color: #4374f3;
          text-align: center;
        }
        .sidebar-container {
          -webkit-transition: width 0.28s;
          transition: width 0.28s;
          width: 992px;
          margin: 0 auto;
        }
      }
      .topmenu-container-right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        .img-icon {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
      }
    }

    .main-container {
      height: calc(100% - 60px);
      width: 100%;
      -webkit-transition: margin-left 0.28s;
      transition: margin-left 0.28s;
      margin-top: 60px;
      position: relative;
    }
  }
}
</style>
