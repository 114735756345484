import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getToken(tokenKey) {
  let key = tokenKey || TokenKey
  return Cookies.get(key)
}

export function setToken(tokenKey, token) {
  let key = tokenKey || TokenKey
  return Cookies.set(key, token)
}

export function removeToken(tokenKey) {
  let key = tokenKey || TokenKey
  return Cookies.remove(key)
}
