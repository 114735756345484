const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  userId: state => state.user.userId,
  userName: state => state.user.userName,
  userType: state => state.user.userType,
  companyId: state => state.user.companyId,
  userInfo: state => state.user.userInfo,
}
export default getters
