import { apilogin, apiphonereg, getloginUser } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    userId: '',
    userName: '',
    userType: '',
    companyId: '',
	avatar: '',
	userInfo: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER_ID: (state, userId) => {
      state.userId = userId
    },
    SET_USER_NAME: (state, userName) => {
      state.userName = userName
    },
    SET_USER_TYPE: (state, userType) => {
      state.userType = userType
    },
    SET_COMPANY_Id: (state, companyId) => {
      state.companyId = companyId
    },
	SET_AVATAR: (state, avatar) => {
	  state.avatar = avatar;
	},
	SET_USERINFO: (state, data) => {
	  state.userInfo = data;
	}
	
  },

  actions: {
    // 密码登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        apilogin(userInfo).then(res => {
		  let result = res.data;
          setToken('token', result.token)
          setToken('userId', result.user.userId)
          setToken('userName', result.user.nickName)
          setToken('userType', result.user.userType)
          setToken('companyId', result.user.companyId)
          setToken('avatar', result.user.avatar)
		  setToken('userInfo', result.user);
          commit('SET_TOKEN', result.token);
          commit('SET_USER_ID', result.user.userId);
          commit('SET_USER_NAME', result.user.nickName);
          commit('SET_USER_TYPE', result.user.userType);
          commit('SET_COMPANY_Id', result.user.companyId);
          commit('SET_AVATAR', result.user.avatar)
		  commit('SET_USERINFO', result.user)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
	
	//验证码登录
	PhoneReg({ commit }, userInfo) {
	  return new Promise((resolve, reject) => {
	    apiphonereg(userInfo).then(res => {
		  let result = res.data;
	      setToken('token', result.token)
		  setToken('userId', result.user.userId)
		  setToken('userName', result.user.nickName)
		  setToken('userType', result.user.userType)
		  setToken('companyId', result.user.companyId)
		  setToken('avatar', result.user.avatar)
		  setToken('userInfo', result.user);
	      commit('SET_TOKEN', result.token);
		  commit('SET_USER_ID', result.user.userId);
		  commit('SET_USER_NAME', result.user.nickName);
		  commit('SET_USER_TYPE', result.user.userType);
		  commit('SET_COMPANY_Id', result.user.companyId);
		  commit('SET_AVATAR', result.user.avatar)
		  commit('SET_USERINFO', result.user)
	      resolve()
	    }).catch(error => {
	      reject(error)
	    })
	  })
	},

    // 获取用户信息
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getloginUser().then(res => {
          const user = res.data
          const avatar = (user.avatar == "" || user.avatar == null) ? require("@/assets/profile.png") :  user.avatar;
          setToken('userId', user.userId)
          setToken('userName', user.nickName)
          setToken('userType', user.userType)
          setToken('companyId', user.companyId)
		  setToken('avatar', avatar)
		  setToken('userInfo', user);
          commit('SET_USER_ID', user.userId);
          commit('SET_USER_NAME', user.nickName);
          commit('SET_USER_TYPE', user.userType);
          commit('SET_COMPANY_Id', user.companyId);
          commit('SET_AVATAR', avatar)
		  commit('SET_USERINFO', user)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        //logout(state.token).then(() => {
		  commit('SET_TOKEN', '');
          commit('SET_USER_ID', '');
          commit('SET_USER_NAME', '');
          commit('SET_USER_TYPE', '');
          commit('SET_COMPANY_Id', '');
          commit('SET_AVATAR', '')
		  commit('SET_USERINFO', {})
          removeToken('token')
		  removeToken('userId')
		  removeToken('userName')
		  removeToken('userType')
		  removeToken('companyId')
		  removeToken('avatar')
		  removeToken('userInfo')
		  removeToken('stagingCompanyId')
          resolve()
        /* }).catch(error => {
          reject(error)
        }) */
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
