import request from '@/utils/request';

// 密码登录
export function apilogin(data) {
  return request({
    url: '/api/login',
    method: 'post',
    data: data
  })
}

// 验证码登录
export function apiphonereg(data) {
	return request({
	  url: '/api/phonereg',
	  method: 'get',
	  params: data
	})
}

// 获取验证码
export function apismscode(data) {
  return request({
    url: '/api/smscode',
    method: 'get',
    headers: {
      isToken: false
    },
  	params: data
  })
}

// 获取用户信息
export function getloginUser(data) {
  return request({
    url: '/api/sys/loginUser',
    method: 'get',
	params: data || null
  })
}